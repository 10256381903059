#main-menu {
  padding:0px 0px;
  margin: 25px 0px 0px 15px;
  height: 45px;
  border-bottom: none;
  float: right;
  font-family: "proximanova";

  @media screen and (max-width: 1024px) {
    float: none;
    margin: 0;
    padding: 0;
  }
}

#main-menu ul {
  width: auto;
  float:left;
  list-style: none;
  margin: 0;
  padding: 0 0px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0 30px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#main-menu li {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight:400;
  font-size: 16px;
  text-transform: uppercase;
  background: none;

  @media screen and (max-width: 1024px) {
    float: none;
    width: 100%;
    border-bottom: 1px solid #bfbfbf;

    // &:nth-last-child(2) {
    //   order: 2;
    // }
    //
    // &:nth-last-child(1) {
    //   order: 1;
    // }
  }
}

#main-menu li > a,
#main-menu li.follow-us {
  color: #fff;
  display: block;
  margin: 0;
  padding: 10px 0px 13px 0px;
  text-decoration: none;
  position: relative;
  font-weight: bold;

  .follow-us-item {
    @media screen and (min-width: 1025px) {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    color: #000;
    font-size: 21px;
    text-transform: uppercase;
    width: 100%;
    padding: 20px 15px;
    font-weight: 400;
  }
}

#main-menu li > a:hover, #main-menu li > a:active, #main-menu a.active, #main-menu > ul > li > a.active-trail {
  color: #f63440;
}

#main-menu li a.sf-with-ul {
  padding-right: 10px;
}

#main-menu li ul li a, #main-menu li ul li a:link, #main-menu li ul li a:visited,
#main-menu li ul li ul li a, #main-menu li ul li ul li a:link, #main-menu li ul li ul li a:visited,
#main-menu li ul li ul li ul li a, #main-menu li ul li ul li ul li a:link, #main-menu li ul li ul li ul li a:visited {
  color:#fff;
  width: 148px;
  margin: 0;
  padding: 10px 10px;
  border-top:1px solid #ddd;
  position: relative;
  font-weight:400;
}

#main-menu ul li ul li:first-child a,#main-menu ul li ul li ul li:first-child a,#main-menu ul li ul li ul li ul li:first-child a  {
  border-top:none;
}


#main-menu li ul li a:hover ,#main-menu li ul li ul li a:hover ,#main-menu li ul li ul li ul li a:hover {
  color: #f63440;
}

#main-menu li ul {
  font-size: 12px;
  z-index: 9999;
  position: absolute;
  left: -999em;
  height: auto;
  width: 160px;
  margin: 0px 0px 0px 0px;
  padding: 5px 5px;
  background:#efefef;
}

#main-menu li ul a {
  width: 150px;
}

#main-menu li ul a:hover, #main-menu li ul a:active {
}

#main-menu li ul ul {
  margin: -44px 0 0 160px;
}

#main-menu li:hover ul ul, #main-menu li:hover ul ul ul, #main-menu li.sfHover ul ul, #main-menu li.sfHover ul ul ul {
  left: -999em;
}

#main-menu li:hover ul, #main-menu li li:hover ul, #main-menu li li li:hover ul, #main-menu li.sfHover ul, #main-menu li li.sfHover ul, #main-menu li li li.sfHover ul {
  left: auto;
}

#main-menu li:hover, #main-menu li.sfHover {
  position: static;
}

#main-menu > ul > li > a {
  padding : 10px 25px 13px 25px;

  @media screen and (max-width: 1024px) {
    padding: 16px 15px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1190px) {
    padding : 10px 17px 13px 17px;
  }
}

/* Menu items */
#main-menu li a.login {
  color: #f63440;
}
span.login-icon {
  background: url("../images/KKG-Login.svg") no-repeat;
  padding-left: 15px;
  margin-left: 10px;
  background-size: 42px auto;
  background-position: -13px center;

  @media screen and (max-width: 1024px) {
    padding-left: 30px;
    background-size: 60px auto;
  }
}

#main-menu li a.facebook-link {
  padding-right: 2px;
  padding-left: 5px;

  @media screen and (max-width: 1024px) {
    padding-left: 17px
  }
}
#main-menu li a.facebook-link span.facebook-icon {
  background: url(../images/KKG-Facebook.svg) no-repeat;
  padding-left: 20px;
  background-size: 50px;
  background-position: -15px center;

  @media screen and (max-width: 1024px) {
    background-image: url("../images/KKG-Facebook-red.svg");
    background-size: 64px;
    padding-left: 33px;
  }
}

#main-menu li a.linkedin-link {
  padding-left: 2px;
  padding-right: 0;
}
#main-menu li a.linkedin-link span.linkedin-icon {
  background: url("../images/KKG-Linkedin.svg") no-repeat;
  padding-left: 20px;
  background-size: 50px;
  background-position: -15px center;

  @media screen and (max-width: 1024px) {
    background-image: url("../images/KKG-Linkedin-red.svg");
    background-size: 64px;
    padding-left: 33px;
  }
}
