@font-face {
    font-family: 'proximanova-bold';
    src: url('../fonts/ProximaNova-Bold.eot');
    src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Bold.woff') format('woff'),
        url('../fonts/ProximaNova-Bold.ttf') format('truetype'),
        url('../fonts/ProximaNova-Bold.svg#../fonts/ProximaNova-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova';
    src: url('../fonts/ProximaNova-Regular.eot');
    src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNova-Regular.woff') format('woff'),
        url('../fonts/ProximaNova-Regular.ttf') format('truetype'),
        url('../fonts/ProximaNova-Regular.svg#../fonts/ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
  font-family: 'kkg';
  src:  url('../fonts/kkg.eot?yikdn9');
  src:  url('../fonts/kkg.eot?yikdn9#iefix') format('embedded-opentype'),
    url('../fonts/kkg.ttf?yikdn9') format('truetype'),
    url('../fonts/kkg.woff?yikdn9') format('woff'),
    url('../fonts/kkg.svg?yikdn9#kkg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"],
.icons {
  /* use !important to prevent issues with browser extensions that change ../fonts */
  font-family: 'kkg' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail-envelope-closed:before {
  content: "\e903";
}
.icon-time:before {
  content: "\e907";
}
.icon-locked:before {
  content: "\e904";
}
.icon-secure:before {
  content: "\e904";
}
.icon-arrow_drop_down:before {
  content: "\e902";
}
.icon-arrow_left:before {
  content: "\e905";
}
.icon-arrow_up:before {
  content: "\e906";
}
.icon-arrow_right:before {
  content: "\e908";
}
.icon-phone:before {
  content: "\e909";
}
.icon-telephone:before {
  content: "\e909";
}
.icon-contact:before {
  content: "\e909";
}
.icon-support:before {
  content: "\e909";
}
.icon-call:before {
  content: "\e909";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-brand:before {
  content: "\e900";
}
.icon-social:before {
  content: "\e900";
}
.icon-linkedin:before {
  content: "\e901";
}
.icon-brand1:before {
  content: "\e901";
}
.icon-social1:before {
  content: "\e901";
}
.icon-fax:before {
  content: "\e90a";
}
