/* About us */
#block-views-our-capabilities-block {
  padding: 23px 0 37px;
}

#block-views-our-capabilities-block > h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 40px;
}

#block-views-our-partners-block > h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  padding: 20px 0 0 0;
}

.page-node #content-wrap .view-our-partners {
  text-align: center;
  display: inline-block;
  padding-bottom: 18px;

  @media screen and (min-width: 768px) {
    width: 85%;
    margin: 0 auto 18px;
  }
}

.view-our-partners .view-content .views-row {
  width: 33.3%;
  float: left;

  @media screen and (min-width: 768px) {
    height: 142px;
  }
}

.page-node #content-wrap .view-our-partners .view-content .views-row .views-field-field-image img {
  border: none;
  margin: 0;
  padding: 0;
  height: auto;
}

.view-our-partners ul.views-fluid-grid-list, .view-our-partners ul.views-fluid-grid-list li {
  background: transparent;
  border: none;
}
