html {
  font-family: 'proximanova';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #414042;
  font-size: 16px;
  font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: .8px;

  &.is-show {
    overflow: hidden;
  }
}


.container {
  @media (min-width: 1200px) {
    max-width: 1174px;
  }

  @media (max-width: 1024px) {
    padding-left: 30px;
    padding-right: 30px;
    max-width: inherit;

    .row {
      margin: 0 auto;
    }

    #primary {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.content-full {
  overflow: hidden;
}

#primary, #sidebar{
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 767px) {
    padding: 0;
  }
}

.col-sm-12 {
  @media (max-width: 767px) {
    padding: 0;
  }
}

#main-content {
  padding-top: 15px;

  @media (max-width: 767px) {
    padding-top: 0;
  }
}

.page-node-8 {
  #primary {
    padding-top: 5px;
    padding-bottom: 30px;
  }
}

#main-content {
  @media (max-width: 767px) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.page-node-5 {
  #main-content {
    @media (max-width: 767px) {
      padding-top: 20px;
    }
  }
}

.pager-show-more {
  a {
    text-transform: uppercase;
    font-weight: bold;

    &:after {
      @extend .icons;
      @extend .icon-arrow_right:before;
      padding-left: 0px;
      color: #F6939B;
      font-size: 25px;
      position: relative;
      bottom: -6px;
    }
  }
}

.load-more-wrap {
  text-align: center;
  margin-top: 20px;

  @media (min-width: 768px) {
    display: none;
  }
}
.load-more {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  color: #f63440 !important;

  &:after {
    @extend .icons;
    @extend .icon-arrow_right:before;
    padding-left: 0px;
    color: #F6939B;
    font-size: 25px;
    position: relative;
    bottom: -6px;
  }
}

.hidden-on-mobile {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.text-center {
  text-align: center;
}
