#masthead .header-top {
  background:rgba( #000, .75);
  transition: .3s;

  @media screen and (max-width: 767px) {
    background: #000;
  }

  .on-scroll & {
    background: #000;
    transition: .3s;
  }
}

.front {
  #masthead.site-header:before {
    display: none;
  }
}

#header-block.front-page {
  padding: 0;

  @media screen and (min-width: 768px) {
    margin-top: -75px;
  }
}

.header-top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;


  &__space {
    height: 68px;
  }
  &__inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .mainmenu {
    position: static;

    @media screen and (min-width: 1025px) {
      display: block !important;
    }

    @media screen and (max-width: 1024px) {
      display: none;
      position: fixed;
      padding-top: 16px;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      top: 67px;
      z-index: 99;
      width: 100%;
      height: calc(100% - 67px);
      overflow: auto;
    }
  }

  .row {
    position: relative;
  }
}

.products-menu {
  &:hover .menu-block-content {
    opacity: 1;
    visibility: visible;
  }
}

.menu-block-content {
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  top: 70px;
  padding: 40px 30px 30px;
  z-index: 99;
  opacity: 0;
  visibility: hidden;

  @media screen and (max-width: 1024px) {
    display: none;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    opacity: 1;
    visibility: visible;

    .warranty .views-row {
      height: 120px;
      overflow: hidden;

      @media screen and (min-width: 1025px) {
        margin-bottom: 10px;
      }
    }
  }

  .title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.view-product-category {
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    margin-bottom: 25px;
  }

  .view-content {
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    margin: 0 -12px;

    @media screen and (max-width: 767px) {
      margin: 0 -6px;
    }
  }

  .views-row {
    width: 33.33%;
    padding: 24px 12px 0;

    @media screen and (max-width: 767px) {
      width: 50%;
      padding: 0 6px 12px;
    }

    .views-field-name {
      height: 100%;
    }

    a {
      display: inline-block;
      width: 100%;
      padding: 20px 10px;
      background: #e6e7e8;
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      color: #414042;
      font-family: "proximanova-bold";

      @media screen and (max-width: 1024px) {
        font-size: 21px;
        height: 100%;
      }

      @media screen and (max-width: 400px) {
        font-size: 16px;
      }

      &:hover {
        background: #f63440;
        color: #fff !important;
      }
    }
  }
}

.site-branding  {
  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 12px 0;

    #site-logo {
      line-height: 0;
      padding: 0;
    }
  }
}

// Menu icons
.menu-wrap {
  line-height: 1;
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.menu-icon {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 30px;
  z-index: 99;

  i {
    border-bottom: 2px solid #fff;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: .5s;
    width: 100%;

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      top: 100%;
      opacity: 1;
    }
  }
}

.is-show {
  .menu-icon {
    i {
      &:nth-child(1) {
        transform: rotate(-45deg);
        top: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        opacity: 0;
      }
    }
  }
}

#masthead.site-header {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(#000, .35);
  }

  .page-title {
    position: relative;
    z-index: 2;
    line-height: 1;

    @media screen and (max-width: 400px) {
      font-size: 34px;
    }
  }
}
