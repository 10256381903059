.contact-map-block {
  iframe {
    width: 100%;
  }
}

.contact-hour-block {
  @media screen and (min-width: 768px) {
    padding-left: 10px;
  }

  .title {
    padding-bottom: 6px;
  }
}


.page-node-9,
.page-node-118 {
  .region-content {
    @media screen and (min-width: 768px) {
      display: flex;
    }

    // #block-system-main {
    //   display: none;
    // }

    .block h2 {
      font-size: 21px;
      font-weight: bolder;
      padding-bottom: 16px;
      text-transform: uppercase;
      color: #414042;
      margin-bottom: 0;
    }
  }
}

.quick-contact-block,
.page-node-118 .webform-client-form {
  @media screen and (max-width: 767px) {
    border-top: 1px solid #ccc;
    margin-top: 30px;
    padding-top: 40px;
  }

  @media screen and (min-width: 768px) {
    padding-left: 17px;
    min-width: 290px;
  }

  h2 {
    padding-bottom: 3px;
    font-size: 21px;
    color: #414042;
  }

  .form-actions {
    padding-top: 2px;
    margin-bottom: 0;
    text-transform: uppercase;
    margin-top: 10px;
    clear: both;

    input {
      margin: 0;
    }
  }

  .form-item {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .form-item-copy {
    display: none;
  }

  #edit-actions input,
  .form-submit {
    text-transform: uppercase;
  }

  .form-text,
  textarea {
    font-size: 14px;
    padding: 6px 10px;
    width: 100%;
    margin: 0;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 14px;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 14px;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      font-size: 14px;
    }
  }
}

.webform-component-textarea .grippie {
  display: none;
}
/* Contact page */
.page-node #content-bottom.contact {
  padding-top: 20px;
}

.page-node #content-bottom.contact #content-wrap img {
  padding: 0;
  border: none;
  max-width: 100%;
  margin: 0 0 -2px 0;
}

.page-node #content-bottom.contact #content-wrap .block .content p {
  margin: 0;
}

.page-node #main-content #content.contact #content-wrap .block {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    float: left;
    width: 33.3%;
    padding-right: 20px;
    padding-left: 0;

    &:last-child {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    float: left;
    width: 33.3%;
  }
}

.contact-hour-block .content .contact-hour > div.title {
  font-size: 21px;
  font-weight: bolder;
  padding-bottom: 16px;
  text-transform: uppercase;
  color: #414042;
  margin-bottom: 0;
}

.contact-head-office-block .content-item, .contact-hour-block .content-item {
  padding-bottom: 15px;
}

.contact-head-office-block .content-item .icon, .contact-hour-block .content-item .icon {
  padding-right: 30px;
  padding-bottom: 5px;
}

.contact-head-office-block .content-item .text, .contact-head-office-block .content-item .text a, .contact-hour-block .list-hour .hour-detail {
  position: relative;
  top: 2px;
  font-size: 16px;
  color: #414042;

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
}

.contact-hour-block .list-hour .hour-detail {
  padding-bottom: 3px;
  left: 0;
  padding-left: 30px;
  padding-right: 10px;
}

.contact-hour-block .content-item .title .text {
  position: relative;
  top: 1px;
  font-size: 16px;
  color: #414042;
  font-weight: bolder;
  padding-bottom: 5px;
}

.contact-head-office-block {
  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.contact-head-office-block .content-item.email .text {
  top: 0px;
}

.contact-head-office-block .content-item .text.new-line{
  left: 30px;
}

.contact-head-office-block .content-item .icon.location {
  background: url("../images/KKG-Location.svg") no-repeat;
}

.contact-head-office-block .content-item .icon.fax {
  background: url("../images/KKG-Fax.svg") no-repeat;
}

.contact-head-office-block .content-item .icon.phone {
  background: url("../images//KKG-Phone.svg") no-repeat;
}

.contact-head-office-block .content-item .icon.email {
  background: url("../images/KKG-Email.svg") no-repeat;
}

.contact-hour-block .content-item .icon.hour {
  background: url("../images/KKG-Hours.svg") no-repeat;
}

.contact-hour-block .content-item .icon.production {
  background: url("../images/KKG-Production.svg") no-repeat;
}

.contact-hour-block .content-item .icon.pickup {
  background: url("../images/KKG-Pickup.svg") no-repeat;
}

.contact-head-office-block .content-item .icon,
.contact-hour-block .content-item .icon {
  position: relative;
  bottom: -2px;
  background-size: 20px !important;
}
