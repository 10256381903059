/* Content top */
#content-top {
  background-color: #f0f0f1;
  color: #414042;
  padding: 50px;

  @media screen and (max-width: 1024px) {
    padding: 39px 0;
  }
}

#content-top .region, #content-top .block {
  margin: 0;
}

#content-top .block {
  padding: 20px 15px;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

#bottom{
  padding: 20px 0px 40px 0px;
  background: #3b3b3b;
  border-top:5px solid #f63440;
}

.footer-block {
  color: #aaa;
}

.footer-block .block > h2{
  font-size: 34px;
  margin-bottom: 10px;
  padding: 10px 0px;
  color:#fff;
  text-transform: uppercase;

  @media screen and (max-width: 400px) {
    font-size: 28px;
  }
}

/* Footer */
#footer-block {
  background-color: #e2e3e4;
  text-align: center;
  color: #414042;
}

/* Footer Menu */
#footer-menu-block {
  background: url(../images/footer-menu-bg.png) no-repeat;
  margin: 5px 0 3px 0;
  padding: 30px 0 35px;
  background-size: cover;

  @media screen and (max-width: 767px) {
    background-size: 275% 100%;
    padding: 50px 0 0;
    margin: 0;

    .container {
      padding: 0;
    }
  }
}

#footer-menu-block .footer-menu {
  padding: 20px 0 13px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    padding: 0;
  }

  img {
    @media screen and (max-width: 1023px) {
      max-width: 160px;
    }
  }
}

#footer-menu-block .footer-menu .left {
  float: left;

  @media screen and (max-width: 767px) {
    float: none;
    padding: 0 30px;
    margin-bottom: 45px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 20px;
  }
}

#footer-menu-block .footer-menu .right {
  float: right;
  padding-top: 15px;
  font-size: 14px;

  @media screen and (max-width: 767px) {
    padding: 27px 30px 17px;
    background: #c61d23;
    width: 100%;

    ul {
      margin: 0;

      li {
        font-size: 12px;

        &:nth-child(2) {
          padding-left: 0;
        }

        &.first {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 27px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#footer-menu-block .footer-menu .right ul li {
  display: inline-block;
  float: left;
  color: #fff;
  text-transform: uppercase;
  padding: 0 0 0 15px;
  background: none;

  @media screen and (max-width: 800px) {
    padding: 0 0 0 10px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 15px 10px 0;

    &:last-child {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 0 0 0 38px;
  }
}

#footer-menu-block .footer-menu .right ul li a {
  color: #fff;
  font-family: "proximanova-bold";
}

.region-footer-copy {
  @media screen and (max-width: 767px) {
    padding: 12px 50px;
  }

  @media screen and (max-width: 400px) {
    padding: 12px 0;
  }
}
