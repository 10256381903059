input,
textarea {
  appearance: none;
  resize: none;
  font-size: 16px;

  &:focus {
    outline: none;
  }
}

button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  padding: 10px 63px;
  font-size: 16px;
  border: 1px solid #000;
  color: #000;
  background-color: transparent;

  &:hover {
    background: #c61d23;
    border-color: #c61d23;
    color: #fff;
    cursor: pointer;
  }
}

.field-name-body {
  ul, ol {
    margin: 0 0 15px 10px;

    li {
      margin-bottom: 10px;
    }
  }

  h4, h5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

a,
p {
  word-wrap: break-word;
}

h1,h2,h3,h4,h5, h6 {
  color: #414042;
}

h1 {
  font-family: "proximanova-bold";
  letter-spacing: 1px;
  font-size: 40px;

  @media screen and (max-width: 400px) {
    font-size: 34px;
  }
}

.top-page-title .page-title{
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 40px;
  margin: 0;
  padding: 32px 0 42px 0;
}

h2 {
  font-size: 34px;
  font-family: "proximanova-bold";
  text-transform: uppercase;
  margin-bottom: 14px;
  letter-spacing: 1px;

  @media screen and (max-width: 400px) {
    font-size: 28px;
  }
}

h3 {
  font-size: 21px;
  font-family: "proximanova-bold";
  text-transform: uppercase;
  color: #414042;
  margin-bottom: 14px;
  letter-spacing: 1px;
}

ul li {
  background: url("../images/icons/list-style.png") no-repeat ;
  padding-left: 20px;
  list-style: none;
  background-position-y: 4px;
}

.grippie {
  display: none;
}

.item-list ul.pager {
  margin-top: 20px;
}


table {
  p {
    margin: 0;
    padding: 0;
  }

  strong {
    font-weight: normal;
  }

  thead th{
    background: #f63440;
    color: #fff;
    border-bottom: 5px solid #fff;
    // padding: 8px 15px;
    padding: 8px;
    font-family: 'proximanova-bold';
    text-align: center;

    &:first-child {
      text-align: left;
    }

    span {
      position: relative;
      right: -30px;
    }
  }

  tbody {
    border-top:1px solid #e2e2e2;
    border-bottom:1px solid #e2e2e2;

    tr:nth-child(odd) {
      background: #f4f5f5;
      color: #414042;
    }

    tr:nth-child(even) {
      background: #fff;
      color: #414042;
    }

    tr {
      padding: 0;
    }

    td {
      border-top:1px solid #e2e2e2;
      color: #414042;
      // padding: 8px 15px;
      padding: 8px;
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }
  }
}

.table-responsive {
  @media (min-width: 1200px) {
    margin: 0;
  }
}



a:hover {
  color: #f63440;
}

.ui-accordion-content {
  table {
    width: 100% !important;
  }

  p > a {
    color: #f63440;
  }
}
