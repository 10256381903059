.slick {
  &__slide img {
    width: 100%;
    height: auto;
  }

  .slick-prev {
    left: -22px;
  }

  .slick-next {
    right: -22px;
  }

  &__arrow {
    button {
      width: auto;
      height: auto;

      &:before {
        @extend .icons;
        // @extend .icon-arrow_left:before;
        font-size: 92px;
        color: #c6333b;
      }

      &.slick-next:before {
        @extend .icon-arrow_right:before;
      }
    }
  }
}
