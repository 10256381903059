input,
textarea {
  appearance: none;
  resize: none;
  font-size: 16px; }
  input:focus,
  textarea:focus {
    outline: none; }

button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  padding: 10px 63px;
  font-size: 16px;
  border: 1px solid #000;
  color: #000;
  background-color: transparent; }
  button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover {
    background: #c61d23;
    border-color: #c61d23;
    color: #fff;
    cursor: pointer; }

.field-name-body ul, .field-name-body ol {
  margin: 0 0 15px 10px; }
  .field-name-body ul li, .field-name-body ol li {
    margin-bottom: 10px; }

.field-name-body h4, .field-name-body h5 {
  margin-top: 20px;
  margin-bottom: 20px; }

a,
p {
  word-wrap: break-word; }

h1, h2, h3, h4, h5, h6 {
  color: #414042; }

h1 {
  font-family: "proximanova-bold";
  letter-spacing: 1px;
  font-size: 40px; }
  @media screen and (max-width: 400px) {
    h1 {
      font-size: 34px; } }

.top-page-title .page-title {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 40px;
  margin: 0;
  padding: 32px 0 42px 0; }

h2 {
  font-size: 34px;
  font-family: "proximanova-bold";
  text-transform: uppercase;
  margin-bottom: 14px;
  letter-spacing: 1px; }
  @media screen and (max-width: 400px) {
    h2 {
      font-size: 28px; } }

h3 {
  font-size: 21px;
  font-family: "proximanova-bold";
  text-transform: uppercase;
  color: #414042;
  margin-bottom: 14px;
  letter-spacing: 1px; }

ul li {
  background: url("../images/icons/list-style.png") no-repeat;
  padding-left: 20px;
  list-style: none;
  background-position-y: 4px; }

.grippie {
  display: none; }

.item-list ul.pager {
  margin-top: 20px; }

table p {
  margin: 0;
  padding: 0; }

table strong {
  font-weight: normal; }

table thead th {
  background: #f63440;
  color: #fff;
  border-bottom: 5px solid #fff;
  padding: 8px;
  font-family: 'proximanova-bold';
  text-align: center; }
  table thead th:first-child {
    text-align: left; }
  table thead th span {
    position: relative;
    right: -30px; }

table tbody {
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2; }
  table tbody tr:nth-child(odd) {
    background: #f4f5f5;
    color: #414042; }
  table tbody tr:nth-child(even) {
    background: #fff;
    color: #414042; }
  table tbody tr {
    padding: 0; }
  table tbody td {
    border-top: 1px solid #e2e2e2;
    color: #414042;
    padding: 8px;
    text-align: center; }
    table tbody td:first-child {
      text-align: left; }

@media (min-width: 1200px) {
  .table-responsive {
    margin: 0; } }

a:hover {
  color: #f63440; }

.ui-accordion-content table {
  width: 100% !important; }

.ui-accordion-content p > a {
  color: #f63440; }

@font-face {
  font-family: 'proximanova-bold';
  src: url("../fonts/ProximaNova-Bold.eot");
  src: url("../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff"), url("../fonts/ProximaNova-Bold.ttf") format("truetype"), url("../fonts/ProximaNova-Bold.svg#../fonts/ProximaNova-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'proximanova';
  src: url("../fonts/ProximaNova-Regular.eot");
  src: url("../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Regular.woff2") format("woff2"), url("../fonts/ProximaNova-Regular.woff") format("woff"), url("../fonts/ProximaNova-Regular.ttf") format("truetype"), url("../fonts/ProximaNova-Regular.svg#../fonts/ProximaNova-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'kkg';
  src: url("../fonts/kkg.eot?yikdn9");
  src: url("../fonts/kkg.eot?yikdn9#iefix") format("embedded-opentype"), url("../fonts/kkg.ttf?yikdn9") format("truetype"), url("../fonts/kkg.woff?yikdn9") format("woff"), url("../fonts/kkg.svg?yikdn9#kkg") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"],
.icons,
.pager-show-more a:after,
.load-more:after,
.slick-arrow:before,
.slick__arrow button:before,
.region-resource .product-resources-block .item-list li a:before {
  /* use !important to prevent issues with browser extensions that change ../fonts */
  font-family: 'kkg' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-mail-envelope-closed:before {
  content: "\e903"; }

.icon-time:before {
  content: "\e907"; }

.icon-locked:before {
  content: "\e904"; }

.icon-secure:before {
  content: "\e904"; }

.icon-arrow_drop_down:before {
  content: "\e902"; }

.icon-arrow_left:before {
  content: "\e905"; }

.icon-arrow_up:before {
  content: "\e906"; }

.icon-arrow_right:before, .pager-show-more a:after, .load-more:after, .slick-arrow:before, .slick__arrow button.slick-next:before, .region-resource .product-resources-block .item-list li a:before {
  content: "\e908"; }

.icon-phone:before {
  content: "\e909"; }

.icon-telephone:before {
  content: "\e909"; }

.icon-contact:before {
  content: "\e909"; }

.icon-support:before {
  content: "\e909"; }

.icon-call:before {
  content: "\e909"; }

.icon-facebook:before {
  content: "\e900"; }

.icon-brand:before {
  content: "\e900"; }

.icon-social:before {
  content: "\e900"; }

.icon-linkedin:before {
  content: "\e901"; }

.icon-brand1:before {
  content: "\e901"; }

.icon-social1:before {
  content: "\e901"; }

.icon-fax:before {
  content: "\e90a"; }

html {
  font-family: 'proximanova';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #414042;
  font-size: 16px;
  font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: .8px; }
  html.is-show {
    overflow: hidden; }

@media (min-width: 1200px) {
  .container {
    max-width: 1174px; } }

@media (max-width: 1024px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
    max-width: inherit; }
    .container .row {
      margin: 0 auto; }
    .container #primary {
      padding-left: 0;
      padding-right: 0; } }

@media (max-width: 400px) {
  .container {
    padding-left: 20px;
    padding-right: 20px; } }

.content-full {
  overflow: hidden; }

#primary, #sidebar {
  padding-top: 20px;
  padding-bottom: 20px; }
  @media (max-width: 767px) {
    #primary, #sidebar {
      padding: 0; } }

@media (max-width: 767px) {
  .col-sm-12 {
    padding: 0; } }

#main-content {
  padding-top: 15px; }
  @media (max-width: 767px) {
    #main-content {
      padding-top: 0; } }

.page-node-8 #primary {
  padding-top: 5px;
  padding-bottom: 30px; }

@media (max-width: 767px) {
  #main-content {
    padding-bottom: 20px;
    padding-top: 20px; } }

@media (max-width: 767px) {
  .page-node-5 #main-content {
    padding-top: 20px; } }

.pager-show-more a {
  text-transform: uppercase;
  font-weight: bold; }
  .pager-show-more a:after {
    padding-left: 0px;
    color: #F6939B;
    font-size: 25px;
    position: relative;
    bottom: -6px; }

.load-more-wrap {
  text-align: center;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .load-more-wrap {
      display: none; } }

.load-more {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  color: #f63440 !important; }
  .load-more:after {
    padding-left: 0px;
    color: #F6939B;
    font-size: 25px;
    position: relative;
    bottom: -6px; }

@media screen and (max-width: 767px) {
  .hidden-on-mobile {
    display: none; } }

.text-center {
  text-align: center; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow {
  overflow: hidden;
  position: absolute;
  text-align: left;
  text-indent: -999em;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  border: 0;
  width: 80px;
  height: 80px;
  padding: 40px;
  background: none; }
  .slick-arrow:before {
    font-size: 80px;
    line-height: 1;
    position: absolute;
    text-indent: 0;
    top: 0;
    color: #fff;
    opacity: .75; }
  .slick-arrow:hover {
    background: none; }
  .slick-arrow:focus {
    outline: none; }

.slick-prev {
  left: -15px; }
  .slick-prev:before {
    left: 0;
    transform: rotate(-180deg); }

.slick-next {
  right: -15px; }
  .slick-next:before {
    right: 0; }

.slick__slide img {
  width: 100%;
  height: auto; }

.slick .slick-prev {
  left: -22px; }

.slick .slick-next {
  right: -22px; }

.slick__arrow button {
  width: auto;
  height: auto; }
  .slick__arrow button:before {
    font-size: 92px;
    color: #c6333b; }

#masthead .header-top {
  background: rgba(0, 0, 0, 0.75);
  transition: .3s; }
  @media screen and (max-width: 767px) {
    #masthead .header-top {
      background: #000; } }
  .on-scroll #masthead .header-top {
    background: #000;
    transition: .3s; }

.front #masthead.site-header:before {
  display: none; }

#header-block.front-page {
  padding: 0; }
  @media screen and (min-width: 768px) {
    #header-block.front-page {
      margin-top: -75px; } }

.header-top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99; }
  .header-top__space {
    height: 68px; }
  .header-top__inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .header-top .mainmenu {
    position: static; }
    @media screen and (min-width: 1025px) {
      .header-top .mainmenu {
        display: block !important; } }
    @media screen and (max-width: 1024px) {
      .header-top .mainmenu {
        display: none;
        position: fixed;
        padding-top: 16px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        top: 67px;
        z-index: 99;
        width: 100%;
        height: calc(100% - 67px);
        overflow: auto; } }
  .header-top .row {
    position: relative; }

.products-menu:hover .menu-block-content {
  opacity: 1;
  visibility: visible; }

.menu-block-content {
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  top: 70px;
  padding: 40px 30px 30px;
  z-index: 99;
  opacity: 0;
  visibility: hidden; }
  @media screen and (max-width: 1024px) {
    .menu-block-content {
      display: none;
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      padding: 0;
      opacity: 1;
      visibility: visible; }
      .menu-block-content .warranty .views-row {
        height: 120px;
        overflow: hidden; } }
    @media screen and (max-width: 1024px) and (min-width: 1025px) {
      .menu-block-content .warranty .views-row {
        margin-bottom: 10px; } }
  .menu-block-content .title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px; }
    @media screen and (max-width: 1024px) {
      .menu-block-content .title {
        display: none; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .view-product-category {
    margin-bottom: 25px; } }

.view-product-category .view-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px; }
  @media screen and (max-width: 767px) {
    .view-product-category .view-content {
      margin: 0 -6px; } }

.view-product-category .views-row {
  width: 33.33%;
  padding: 24px 12px 0; }
  @media screen and (max-width: 767px) {
    .view-product-category .views-row {
      width: 50%;
      padding: 0 6px 12px; } }
  .view-product-category .views-row .views-field-name {
    height: 100%; }
  .view-product-category .views-row a {
    display: inline-block;
    width: 100%;
    padding: 20px 10px;
    background: #e6e7e8;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    color: #414042;
    font-family: "proximanova-bold"; }
    @media screen and (max-width: 1024px) {
      .view-product-category .views-row a {
        font-size: 21px;
        height: 100%; } }
    @media screen and (max-width: 400px) {
      .view-product-category .views-row a {
        font-size: 16px; } }
    .view-product-category .views-row a:hover {
      background: #f63440;
      color: #fff !important; }

@media screen and (max-width: 1024px) {
  .site-branding {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 12px 0; }
    .site-branding #site-logo {
      line-height: 0;
      padding: 0; } }

.menu-wrap {
  line-height: 1;
  display: none; }
  @media screen and (max-width: 1024px) {
    .menu-wrap {
      display: block; } }

.menu-icon {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 30px;
  z-index: 99; }
  .menu-icon i {
    border-bottom: 2px solid #fff;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: .5s;
    width: 100%; }
    .menu-icon i:nth-child(2) {
      top: 50%; }
    .menu-icon i:nth-child(3) {
      top: 100%;
      opacity: 1; }

.is-show .menu-icon i:nth-child(1) {
  transform: rotate(-45deg);
  top: 50%; }

.is-show .menu-icon i:nth-child(2) {
  transform: rotate(45deg); }

.is-show .menu-icon i:nth-child(3) {
  opacity: 0; }

#masthead.site-header {
  position: relative; }
  #masthead.site-header:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.35); }
  #masthead.site-header .page-title {
    position: relative;
    z-index: 2;
    line-height: 1; }
    @media screen and (max-width: 400px) {
      #masthead.site-header .page-title {
        font-size: 34px; } }

.slideshow-block {
  overflow: hidden;
  margin-top: -1px; }
  .slideshow-block .views-field-field-image {
    width: 100%;
    overflow: hidden;
    position: relative; }
    .slideshow-block .views-field-field-image:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.15); }
    @media screen and (max-width: 767px) {
      .slideshow-block .views-field-field-image {
        height: 433px; }
        .slideshow-block .views-field-field-image img {
          position: absolute;
          left: 50%;
          top: 50%;
          height: 100%;
          width: auto;
          transform: translate(-50%, -50%);
          max-width: inherit; } }
    @media screen and (min-width: 1280px) {
      .slideshow-block .views-field-field-image {
        height: calc(100vh + 8px); } }
    .slideshow-block .views-field-field-image .field-content {
      width: 100%;
      height: 100%; }

.slideshow-block .views-field-title {
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  font-size: 30px;
  font-weight: bolder;
  width: 100%;
  text-align: center;
  padding: 0 70px;
  transform: translateY(-50%);
  font-family: "proximanova-bold";
  line-height: 1.1; }
  @media screen and (max-width: 767px) {
    .slideshow-block .views-field-title {
      padding: 0 40px; } }
  @media screen and (min-width: 1024px) {
    .slideshow-block .views-field-title {
      padding: 0 20%;
      font-size: 40px; } }
  .slideshow-block .views-field-title .field-content {
    background: url("../images/bg-text-slider.png") no-repeat scroll center center transparent;
    background-size: 100% 100%;
    display: inline-block; }
    @media screen and (min-width: 1024px) {
      .slideshow-block .views-field-title .field-content {
        padding: 50px 20px; } }

.slideshow-block .views-field-title a,
.slideshow-block .views-field-title {
  color: #fff; }

#block-views-our-partners-block, #block-views-messages-block {
  width: 100%; }
  @media screen and (min-width: 768px) {
    #block-views-our-partners-block, #block-views-messages-block {
      text-align: center; } }

@media screen and (max-width: 767px) {
  .message-block {
    padding: 21px 0; } }

.box-service {
  overflow: hidden;
  padding: 6px 0;
  margin-bottom: 15px; }
  @media screen and (min-width: 768px) {
    .box-service .view-content {
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  .box-service .views-row {
    position: relative;
    height: auto;
    display: flex; }
    @media screen and (max-width: 767px) {
      .box-service .views-row {
        margin-bottom: 38px; }
        .box-service .views-row:last-child {
          margin-bottom: 0; } }
    @media screen and (max-width: 400px) {
      .box-service .views-row {
        flex-wrap: wrap;
        text-align: center; } }
    @media screen and (min-width: 768px) {
      .box-service .views-row {
        width: 286px;
        height: 288px;
        justify-content: center;
        align-items: flex-end;
        height: 250px;
        padding-bottom: 30px; } }
    @media screen and (min-width: 1024px) {
      .box-service .views-row {
        padding-bottom: 0;
        align-items: center; } }
    .box-service .views-row:hover .box-service__content {
      display: block; }
    .box-service .views-row:hover .box-service__wrap h3 {
      opacity: 0; }
  .box-service__image {
    margin-bottom: 29px; }
    @media screen and (max-width: 1023px) {
      .box-service__image img {
        max-width: 100px;
        margin: 0 auto; } }
    @media screen and (max-width: 767px) {
      .box-service__image {
        margin: 0 48px 0 0; }
        .box-service__image img {
          max-width: 123px; } }
    @media screen and (max-width: 400px) {
      .box-service__image {
        margin: 0 auto; } }
  @media screen and (max-width: 400px) {
    .box-service__wrap {
      margin: 0 auto 10px; } }
  @media screen and (max-width: 767px) {
    .box-service__wrap > .box-service__title {
      display: none; } }
  .box-service__title {
    margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    .box-service__content {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(230, 231, 232, 0.97);
      padding: 20px 10px;
      overflow: auto; } }
  @media screen and (min-width: 1024px) {
    .box-service__content {
      padding: 20px; } }
  @media screen and (max-width: 1023px) and (min-width: 768px) {
    .box-service__content {
      font-size: 14px; } }
  .box-service__content .box-service__title {
    margin-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .box-service__content .box-service__title {
        margin-bottom: 11px; } }
  .box-service__content p {
    margin: 0; }

.all-products-block {
  margin-bottom: 30px; }

@media screen and (min-width: 768px) {
  .warranty .view-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap; } }

.warranty .views-row {
  position: relative; }
  @media screen and (max-width: 767px) {
    .warranty .views-row {
      height: 251px;
      overflow: hidden;
      position: relative; }
      .warranty .views-row img {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
  @media screen and (min-width: 768px) {
    .warranty .views-row {
      width: 33.33%; } }
  .warranty .views-row > .warranty__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    margin: 0;
    background: url("../images/bg-text.png") no-repeat scroll center center transparent;
    background-size: 100% 100%;
    padding: 20px;
    transition: .3s; }
    @media screen and (min-width: 768px) {
      .warranty .views-row > .warranty__title {
        padding: 20px 0; } }
  .warranty .views-row:hover .warranty__content {
    background: rgba(246, 52, 64, 0.6);
    transition: .3s; }
  .warranty .views-row:hover .warranty__inner {
    transition: .3s;
    opacity: 1;
    visibility: visible; }
  .warranty .views-row:hover > .warranty__title {
    opacity: 0;
    transition: .3s; }

.warranty__title {
  color: #fff;
  margin-bottom: 20px;
  font-size: 34px;
  line-height: 1;
  padding: 20px; }
  @media screen and (min-width: 768px) {
    .warranty__title {
      max-width: 310px;
      padding: 0; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .warranty__title {
      font-size: 24px; } }
  @media screen and (max-width: 400px) {
    .warranty__title {
      font-size: 24px; } }

.warranty__image {
  line-height: 0; }
  .warranty__image img {
    width: 100%;
    height: auto; }
  .warranty__image a:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    content: ''; }

.warranty__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 20px 10px;
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s; }
  @media screen and (min-width: 1024px) {
    .warranty__content {
      padding: 30px 15px; } }
  .warranty__content h3 {
    display: none;
    max-width: inherit; }
    @media screen and (min-width: 1024px) {
      .warranty__content h3 {
        display: block; } }
    @media screen and (max-width: 1024px) {
      .warranty__content h3 {
        margin-bottom: 5px; } }
    @media screen and (min-width: 1280px) {
      .warranty__content h3 {
        font-size: 35px; } }

.warranty__inner {
  opacity: 0;
  transition: .3s;
  visibility: hidden; }

.warranty__body {
  margin-bottom: 40px;
  padding: 0 20px; }
  @media screen and (max-width: 1024px) {
    .warranty__body {
      font-size: 14px;
      margin-bottom: 30px;
      padding: 0; } }

.warranty__link a {
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  padding: 11px 35px; }
  @media screen and (max-width: 1024px) {
    .warranty__link a {
      font-size: 14px;
      padding: 9px 35px; } }
  .warranty__link a:before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    transition: .3s;
    position: absolute;
    width: 100%;
    content: ''; }

.recent-projects-block {
  text-align: center;
  padding: 90px 0 61px; }
  @media screen and (max-width: 767px) {
    .recent-projects-block {
      padding: 40px 0 30px; } }
  .recent-projects-block h2 {
    margin-bottom: 65px; }
    @media screen and (max-width: 767px) {
      .recent-projects-block h2 {
        margin-bottom: 19px; } }

.grid-3cols .view-content,
.grid-4cols .view-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between; }

.grid-3cols .views-row,
.grid-4cols .views-row {
  margin-bottom: 25px;
  margin-right: 25px; }
  @media screen and (max-width: 767px) {
    .grid-3cols .views-row,
    .grid-4cols .views-row {
      margin-bottom: 15px;
      width: 100%; } }
  .grid-3cols .views-row a,
  .grid-4cols .views-row a {
    position: relative;
    height: 100%;
    display: inline-block;
    width: 100%; }
    .grid-3cols .views-row a:before,
    .grid-4cols .views-row a:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(246, 52, 64, 0.6);
      opacity: 0;
      visibility: hidden;
      transition: .3s; }
    .grid-3cols .views-row a img,
    .grid-4cols .views-row a img {
      width: 100%;
      height: auto; }
  .grid-3cols .views-row:hover a:before,
  .grid-4cols .views-row:hover a:before {
    opacity: 1;
    visibility: visible;
    transition: .3s; }
  .grid-3cols .views-row img,
  .grid-4cols .views-row img {
    width: 100%;
    height: auto; }

.grid-3cols .views-field-colorbox,
.grid-4cols .views-field-colorbox {
  line-height: 0; }

.grid-4cols .views-row {
  width: calc((100% - 32px) / 4);
  margin-bottom: 0;
  margin-right: 10px; }
  @media screen and (max-width: 767px) {
    .grid-4cols .views-row {
      width: calc((100% - 8px) / 2);
      margin-bottom: 5px;
      margin-right: 7px; }
      .grid-4cols .views-row:nth-child(2n) {
        margin-right: 0; } }
  .grid-4cols .views-row:nth-child(4n) {
    margin-right: 0; }

@media screen and (min-width: 768px) {
  .grid-3cols .views-row {
    width: calc((100% - 52px) / 3); } }

@media screen and (max-width: 767px) {
  .grid-3cols .views-row {
    margin-right: 0; }
    .grid-3cols .views-row:last-child {
      margin-bottom: 0; } }

.grid-3cols .views-row:nth-child(3n) {
  margin-right: 0; }

.block-simplenews {
  padding: 34px 0 53px;
  margin: 0 auto;
  max-width: 950px; }
  @media screen and (max-width: 767px) {
    .block-simplenews {
      padding: 12px 0 42px; } }
  .block-simplenews p {
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .block-simplenews p {
        margin-bottom: 36px; } }
  .block-simplenews .form-text {
    margin: 0;
    height: 38px;
    padding: 10px 15px;
    width: 100%;
    background: #fff; }
    @media screen and (min-width: 768px) {
      .block-simplenews .form-text {
        margin: 0 10px; } }
  @media screen and (min-width: 768px) {
    .block-simplenews .webform-component-textfield,
    .block-simplenews .webform-component-email {
      width: 50%;
      float: left;
      padding: 0 15px; } }
  .block-simplenews .form-submit {
    margin-top: 24px !important;
    text-transform: uppercase; }
    @media screen and (max-width: 767px) {
      .block-simplenews .form-submit {
        margin-top: 17px !important; } }

.next-section {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  color: #c3beba;
  display: none; }
  @media screen and (min-width: 1280px) {
    .next-section {
      display: block; } }
  .next-section:hover {
    color: #f63440; }
  .next-section span {
    cursor: pointer;
    font-size: 130px; }

.quick-contact {
  background: rgba(0, 20, 137, 0.5);
  position: fixed;
  top: 60%;
  width: 768px;
  padding: 18px 14px 14px 55px;
  z-index: 9;
  right: -727px;
  transition: .3s;
  display: none;
  height: 206px; }
  .quick-contact label,
  .quick-contact .webform-component-markup,
  .quick-contact .webform-component--what-is-the-first-letter-in-glass {
    display: none !important; }
  @media screen and (min-width: 768px) {
    .quick-contact {
      display: block; } }
  .quick-contact.is-show {
    right: 0;
    transition: .3s; }
  .quick-contact .webform-client-form {
    padding: 0;
    min-width: inherit; }
  .quick-contact .messages {
    display: none; }
  .quick-contact .form-item-captcha-questions-answer-given {
    display: none; }
  .quick-contact__title {
    font-size: 21px;
    text-transform: uppercase;
    position: absolute;
    left: -83px;
    top: 83px;
    transform: rotate(-90deg);
    background: url("../images/bg-quick-contact.png") no-repeat scroll 0 0 transparent;
    background-size: cover;
    color: #fff;
    padding: 8px 5px 5px;
    margin: 0;
    letter-spacing: 0px;
    cursor: pointer;
    width: 206px;
    text-align: center; }
  .quick-contact form > div {
    display: flex; }
  .quick-contact form .form-text {
    max-width: 239px;
    margin-top: 0;
    height: 33px;
    background: #fff; }
  .quick-contact .form-item {
    margin-top: 0;
    margin-bottom: 7px; }
  .quick-contact .form-actions {
    margin: 12px 0 0;
    padding: 0;
    max-width: 239px; }
    .quick-contact .form-actions input {
      margin: 0;
      width: 100%;
      color: #fff;
      border: 1px solid #fff;
      text-transform: uppercase; }
      .quick-contact .form-actions input:hover {
        border-color: #c61d23; }
  .quick-contact .grippie {
    display: none; }
  .quick-contact .form-item-subject {
    display: none; }
  .quick-contact .form-type-textarea,
  .quick-contact .webform-component-textarea {
    margin-left: 10px;
    margin-bottom: 0; }
    .quick-contact .form-type-textarea textarea,
    .quick-contact .webform-component-textarea textarea {
      height: 170px;
      padding: 10px;
      background: #fff; }
  .quick-contact .form-item-copy {
    display: none; }

#block-views-about-us-block .view-content .views-field-body {
  padding-bottom: 10px; }
  @media screen and (max-width: 767px) {
    #block-views-about-us-block .view-content .views-field-body {
      padding-bottom: 0; } }

@media screen and (max-width: 1000px) {
  .front #primary {
    padding-left: 0;
    padding-right: 0; } }

.read-more {
  position: relative; }
  .read-more:before {
    position: absolute;
    right: -30px;
    background: url(../images/icons/list-style.png) no-repeat;
    padding-left: 15px;
    top: 3px;
    content: '';
    width: 10px;
    height: 15px; }

textarea,
input,
button {
  border-radius: 0; }

.contact-map-block iframe {
  width: 100%; }

@media screen and (min-width: 768px) {
  .contact-hour-block {
    padding-left: 10px; } }

.contact-hour-block .title {
  padding-bottom: 6px; }

@media screen and (min-width: 768px) {
  .page-node-9 .region-content,
  .page-node-118 .region-content {
    display: flex; } }

.page-node-9 .region-content .block h2,
.page-node-118 .region-content .block h2 {
  font-size: 21px;
  font-weight: bolder;
  padding-bottom: 16px;
  text-transform: uppercase;
  color: #414042;
  margin-bottom: 0; }

@media screen and (max-width: 767px) {
  .quick-contact-block,
  .page-node-118 .webform-client-form {
    border-top: 1px solid #ccc;
    margin-top: 30px;
    padding-top: 40px; } }

@media screen and (min-width: 768px) {
  .quick-contact-block,
  .page-node-118 .webform-client-form {
    padding-left: 17px;
    min-width: 290px; } }

.quick-contact-block h2,
.page-node-118 .webform-client-form h2 {
  padding-bottom: 3px;
  font-size: 21px;
  color: #414042; }

.quick-contact-block .form-actions,
.page-node-118 .webform-client-form .form-actions {
  padding-top: 2px;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-top: 10px;
  clear: both; }
  .quick-contact-block .form-actions input,
  .page-node-118 .webform-client-form .form-actions input {
    margin: 0; }

.quick-contact-block .form-item,
.page-node-118 .webform-client-form .form-item {
  margin-top: 0;
  margin-bottom: 10px; }

.quick-contact-block .form-item-copy,
.page-node-118 .webform-client-form .form-item-copy {
  display: none; }

.quick-contact-block #edit-actions input,
.quick-contact-block .form-submit,
.page-node-118 .webform-client-form #edit-actions input,
.page-node-118 .webform-client-form .form-submit {
  text-transform: uppercase; }

.quick-contact-block .form-text,
.quick-contact-block textarea,
.page-node-118 .webform-client-form .form-text,
.page-node-118 .webform-client-form textarea {
  font-size: 14px;
  padding: 6px 10px;
  width: 100%;
  margin: 0; }
  .quick-contact-block .form-text::placeholder,
  .quick-contact-block textarea::placeholder,
  .page-node-118 .webform-client-form .form-text::placeholder,
  .page-node-118 .webform-client-form textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px; }
  .quick-contact-block .form-text:-ms-input-placeholder,
  .quick-contact-block textarea:-ms-input-placeholder,
  .page-node-118 .webform-client-form .form-text:-ms-input-placeholder,
  .page-node-118 .webform-client-form textarea:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 14px; }
  .quick-contact-block .form-text::-ms-input-placeholder,
  .quick-contact-block textarea::-ms-input-placeholder,
  .page-node-118 .webform-client-form .form-text::-ms-input-placeholder,
  .page-node-118 .webform-client-form textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 14px; }

.webform-component-textarea .grippie {
  display: none; }

/* Contact page */
.page-node #content-bottom.contact {
  padding-top: 20px; }

.page-node #content-bottom.contact #content-wrap img {
  padding: 0;
  border: none;
  max-width: 100%;
  margin: 0 0 -2px 0; }

.page-node #content-bottom.contact #content-wrap .block .content p {
  margin: 0; }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .page-node #main-content #content.contact #content-wrap .block {
    float: left;
    width: 33.3%;
    padding-right: 20px;
    padding-left: 0; }
    .page-node #main-content #content.contact #content-wrap .block:last-child {
      padding-right: 0; } }

@media screen and (min-width: 1024px) {
  .page-node #main-content #content.contact #content-wrap .block {
    float: left;
    width: 33.3%; } }

.contact-hour-block .content .contact-hour > div.title {
  font-size: 21px;
  font-weight: bolder;
  padding-bottom: 16px;
  text-transform: uppercase;
  color: #414042;
  margin-bottom: 0; }

.contact-head-office-block .content-item, .contact-hour-block .content-item {
  padding-bottom: 15px; }

.contact-head-office-block .content-item .icon, .contact-hour-block .content-item .icon {
  padding-right: 30px;
  padding-bottom: 5px; }

.contact-head-office-block .content-item .text, .contact-head-office-block .content-item .text a, .contact-hour-block .list-hour .hour-detail {
  position: relative;
  top: 2px;
  font-size: 16px;
  color: #414042; }
  @media screen and (min-width: 768px) {
    .contact-head-office-block .content-item .text, .contact-head-office-block .content-item .text a, .contact-hour-block .list-hour .hour-detail {
      font-size: 14px; } }
  @media screen and (min-width: 1024px) {
    .contact-head-office-block .content-item .text, .contact-head-office-block .content-item .text a, .contact-hour-block .list-hour .hour-detail {
      font-size: 16px; } }

.contact-hour-block .list-hour .hour-detail {
  padding-bottom: 3px;
  left: 0;
  padding-left: 30px;
  padding-right: 10px; }

.contact-hour-block .content-item .title .text {
  position: relative;
  top: 1px;
  font-size: 16px;
  color: #414042;
  font-weight: bolder;
  padding-bottom: 5px; }

@media screen and (max-width: 767px) {
  .contact-head-office-block {
    margin-bottom: 15px; } }

.contact-head-office-block .content-item.email .text {
  top: 0px; }

.contact-head-office-block .content-item .text.new-line {
  left: 30px; }

.contact-head-office-block .content-item .icon.location {
  background: url("../images/KKG-Location.svg") no-repeat; }

.contact-head-office-block .content-item .icon.fax {
  background: url("../images/KKG-Fax.svg") no-repeat; }

.contact-head-office-block .content-item .icon.phone {
  background: url("../images//KKG-Phone.svg") no-repeat; }

.contact-head-office-block .content-item .icon.email {
  background: url("../images/KKG-Email.svg") no-repeat; }

.contact-hour-block .content-item .icon.hour {
  background: url("../images/KKG-Hours.svg") no-repeat; }

.contact-hour-block .content-item .icon.production {
  background: url("../images/KKG-Production.svg") no-repeat; }

.contact-hour-block .content-item .icon.pickup {
  background: url("../images/KKG-Pickup.svg") no-repeat; }

.contact-head-office-block .content-item .icon,
.contact-hour-block .content-item .icon {
  position: relative;
  bottom: -2px;
  background-size: 20px !important; }

.projects-block {
  margin-top: 44px;
  margin-bottom: 27px; }
  @media screen and (max-width: 767px) {
    .projects-block {
      margin-top: 30px;
      margin-bottom: 20px; } }

#cboxWrapper {
  background: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto !important; }
  @media screen and (max-width: 1024px) {
    #cboxWrapper {
      width: 100% !important; } }

#cboxPrevious {
  background: url("../images/icons/prev.png") no-repeat scroll center center transparent;
  left: -40px; }
  @media screen and (min-width: 1025px) {
    #cboxPrevious {
      left: -79px; } }

#cboxPrevious,
#cboxNext {
  top: 50%;
  height: 40px;
  width: 50px;
  margin-bottom: 0;
  outline: none;
  bottom: inherit;
  transform: translate(0, -50%); }
  #cboxPrevious:focus,
  #cboxNext:focus {
    outline: none; }
  #cboxPrevious:hover,
  #cboxNext:hover {
    background-position: center center; }

#cboxNext {
  left: inherit;
  background: url("../images/icons/next.png") no-repeat scroll center center transparent;
  right: -40px; }
  @media screen and (min-width: 1025px) {
    #cboxNext {
      right: -79px; } }

#cboxClose {
  background: url("../images/icons/close.svg") no-repeat scroll 0 0 transparent;
  background-size: 17px auto;
  position: fixed;
  top: 15px;
  right: 10px;
  display: none; }
  #cboxClose:focus {
    outline: none; }
  #cboxClose:hover {
    background-position: 0 0; }

#cboxCurrent {
  display: none !important; }

#cboxLoadedContent {
  margin: 0;
  height: auto !important; }
  @media screen and (max-width: 1024px) {
    #cboxLoadedContent {
      height: auto !important;
      width: 100% !important; } }
  #cboxLoadedContent .field-title {
    display: none; }

#colorbox,
#cboxOverlay,
#cboxWrapper {
  overflow: inherit; }

#cboxOverlay {
  background: none;
  opacity: 1 !important;
  transition: .3s; }
  #cboxOverlay:before {
    background: url("../images/icons/close.svg") no-repeat scroll 0 0 transparent;
    background-size: 17px auto;
    position: absolute;
    top: 15px;
    right: 10px;
    content: '';
    width: 17px;
    height: 17px;
    z-index: 2;
    transition: .3s; }
  #cboxOverlay:after {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .75 !important;
    background: #000;
    z-index: 1;
    transition: .3s; }

#cboxMiddleLeft,
#cboxMiddleRight {
  width: 1px;
  height: 100px !important; }

@media screen and (max-width: 1024px) {
  #colorbox {
    width: 80% !important;
    left: 50% !important;
    top: 50% !important;
    position: fixed !important;
    transform: translate(-50%, -50%);
    margin-top: -7px; } }

#cboxContent {
  position: relative;
  overflow: inherit;
  background: none;
  height: auto !important; }
  @media screen and (max-width: 1024px) {
    #cboxContent {
      width: 100% !important; } }
  #cboxContent .field-image {
    line-height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; }
    #cboxContent .field-image img {
      width: auto;
      height: auto;
      max-height: 95vh; }

.field-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 10px 20px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .field-title {
      font-size: 13px;
      padding: 5px; } }

.node-type-products .field-name-field-image {
  margin-bottom: 39px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .node-type-products .field-name-field-image {
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 400px) {
    .node-type-products .field-name-field-image {
      margin-left: -20px;
      margin-right: -20px; } }
  .node-type-products .field-name-field-image .field-items {
    display: flex;
    justify-content: center; }
  .node-type-products .field-name-field-image img {
    width: 100%;
    height: auto; }

.node-type-products .field-name-body {
  margin-bottom: 35px; }
  @media screen and (max-width: 767px) {
    .node-type-products .field-name-body {
      margin-bottom: 48px; } }

.node-type-products .product-specification-title {
  text-align: center;
  margin-bottom: 29px;
  line-height: 1.2; }

@media screen and (max-width: 767px) {
  .node-type-products .group-product-specifications {
    margin-left: -15px;
    margin-right: -15px; } }

.node-type-products .region-content {
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .node-type-products .region-content {
      margin-bottom: 30px; } }

.node-type-products .field-collection-container,
.node-type-products .field-collection-container .field-items .field-item {
  margin-bottom: 0;
  border: 0; }

.node-type-products .field-name-field-architectural-solution .field-collection-view {
  margin: 0 0 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #ccc; }

.node-type-products .field-name-field-architectural-solution .field-collection-view-final {
  margin: 0; }

.node-type-products .field-name-field-architectural-solution .content {
  display: flex; }

.node-type-products .field-name-field-architectural-solution .field-type-image {
  width: 29%;
  line-height: 0; }
  .node-type-products .field-name-field-architectural-solution .field-type-image img {
    width: 100%;
    height: auto; }

.node-type-products .field-name-field-architectural-solution .field-name-field-solution-information {
  width: 71%;
  padding-left: 15px; }

.resource-region {
  background: url("../images/bg-resource.jpg") no-repeat scroll 0 0 transparent;
  background-size: cover;
  color: #fff;
  padding: 36px 0 31px; }

@media screen and (min-width: 768px) {
  .region-resource {
    display: flex;
    justify-content: space-between; } }

.region-resource h2 {
  font-size: 21px;
  color: #fff; }

.region-resource a {
  color: #fff; }

.region-resource .product-resources-block {
  position: relative; }
  @media screen and (min-width: 768px) {
    .region-resource .product-resources-block {
      width: 61%; } }
  @media screen and (max-width: 767px) {
    .region-resource .product-resources-block {
      margin-bottom: 20px;
      overflow: hidden; } }
  @media screen and (max-width: 1370px) {
    .region-resource .product-resources-block {
      padding-left: 105px; } }
  .region-resource .product-resources-block:before {
    position: absolute;
    content: '';
    left: -105px;
    top: -5px;
    background: url(../images/resources.svg) no-repeat scroll 0 0 transparent;
    width: 78px;
    height: 87px; }
    @media screen and (max-width: 1370px) {
      .region-resource .product-resources-block:before {
        left: 0;
        top: 0; } }
  .region-resource .product-resources-block .item-list ul {
    padding-left: 0; }
  .region-resource .product-resources-block .item-list li {
    margin-bottom: 10px;
    background: none; }
    @media screen and (min-width: 1025px) {
      .region-resource .product-resources-block .item-list li {
        width: calc((100% - 11px) / 2);
        float: left;
        padding-left: 22px;
        margin-right: 10px; }
        .region-resource .product-resources-block .item-list li:nth-child(2n) {
          margin-right: 0; } }
    .region-resource .product-resources-block .item-list li a {
      position: relative; }
      .region-resource .product-resources-block .item-list li a:before {
        position: absolute;
        left: -30px;
        top: -3px;
        font-size: 27px;
        width: 20px; }

.region-resource #block-views-warranty-block {
  text-align: center; }
  @media screen and (max-width: 767px) {
    .region-resource #block-views-warranty-block {
      background: url("../images/bg-download.jpg") no-repeat scroll 0 0 transparent;
      background-size: 100% 100%;
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: -30px;
      padding: 30px 0; } }
  @media screen and (max-width: 767px) and (max-width: 400px) {
    .region-resource #block-views-warranty-block {
      margin-left: -20px;
      margin-right: -20px; } }
  @media screen and (min-width: 768px) {
    .region-resource #block-views-warranty-block {
      width: 35%; } }
  @media screen and (min-width: 1280px) {
    .region-resource #block-views-warranty-block {
      padding-right: 160px; } }

.region-resource .views-field-field-warranty a {
  padding: 10px 45px;
  font-size: 16px;
  border: 1px solid #fff;
  color: #fff;
  margin-top: 9px;
  display: inline-block;
  text-transform: uppercase; }

.region-related-product {
  text-align: center;
  padding: 49px 0; }
  .region-related-product h2 {
    margin-bottom: 36px; }

.view-related-products .view-content,
.menu-product .view-content {
  margin: 0 -12px; }

.view-related-products .views-row,
.menu-product .views-row {
  padding: 0 12px;
  height: auto; }
  @media screen and (max-width: 767px) {
    .view-related-products .views-row,
    .menu-product .views-row {
      margin-bottom: 10px; } }
  .view-related-products .views-row:hover a:before,
  .menu-product .views-row:hover a:before {
    background: rgba(246, 52, 64, 0.6);
    transition: .3s; }
  .view-related-products .views-row:hover > .warranty__title,
  .menu-product .views-row:hover > .warranty__title {
    background: none;
    transition: .3s;
    opacity: 1; }
  .view-related-products .views-row a,
  .menu-product .views-row a {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%; }
    .view-related-products .views-row a:before,
    .menu-product .views-row a:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.35);
      content: '';
      transition: .3s;
      z-index: 2; }
  .view-related-products .views-row img,
  .menu-product .views-row img {
    position: relative;
    top: 0;
    left: 0;
    transform: none; }

.view-related-products .warranty__title,
.menu-product .warranty__title {
  padding: 0 20px !important;
  z-index: 2;
  transition: .3s; }
  @media screen and (min-width: 768px) {
    .view-related-products .warranty__title,
    .menu-product .warranty__title {
      font-size: 24px; } }
  @media screen and (min-width: 1200px) {
    .view-related-products .warranty__title,
    .menu-product .warranty__title {
      font-size: 28px; } }

#related-product-block {
  overflow: hidden; }

.view-related-products .slick-prev {
  left: -35px; }
  @media screen and (min-width: 480px) {
    .view-related-products .slick-prev {
      left: -40px; } }
  @media screen and (min-width: 768px) {
    .view-related-products .slick-prev {
      left: -50px; } }

.view-related-products .slick-next {
  right: -35px; }
  @media screen and (min-width: 480px) {
    .view-related-products .slick-next {
      right: -43px; } }
  @media screen and (min-width: 768px) {
    .view-related-products .slick-next {
      right: -53px; } }

.view-related-products .slick-arrow:before {
  color: #f63440;
  opacity: 1; }

@media screen and (max-width: 767px) {
  .view-related-products .warranty__title {
    font-size: 21px; } }

.all-products-block .view-header,
.view-taxonomy-term .view-header {
  margin-bottom: 20px; }

.all-products-block .views-row,
.view-taxonomy-term .views-row {
  margin-bottom: 10px; }
  @media screen and (min-width: 768px) {
    .all-products-block .views-row,
    .view-taxonomy-term .views-row {
      margin-right: 25px;
      width: calc((100% - 52px) / 3);
      margin-bottom: 20px;
      height: auto;
      overflow: hidden; } }
  .all-products-block .views-row:nth-child(3n),
  .view-taxonomy-term .views-row:nth-child(3n) {
    margin-right: 0; }
  .all-products-block .views-row img,
  .view-taxonomy-term .views-row img {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    max-width: inherit;
    width: 100%;
    height: auto; }

.all-products-block .views-field-field-image,
.view-taxonomy-term .views-field-field-image {
  line-height: 0; }

.ui-accordion-header {
  border: none;
  border-radius: 0;
  background: #f0f1f1;
  padding: 13px 10px 12px 20px;
  position: relative; }
  .ui-accordion-header:before {
    content: '';
    background: url("../images/icons/accordion-close.png") no-repeat;
    position: absolute;
    top: 50%;
    right: 17px;
    width: 28px;
    height: 12px;
    margin-top: -6px; }
    @media screen and (max-width: 400px) {
      .ui-accordion-header:before {
        width: 20px;
        height: 10px;
        margin-top: -4px;
        background-size: 20px; } }
  .ui-accordion-header.ui-accordion-header-active:before {
    background-image: url("../images/icons/accordion-active.png"); }
  .ui-accordion-header a {
    font-weight: bolder;
    font-size: 21px;
    text-transform: uppercase;
    color: #414042 !important; }
    @media screen and (max-width: 400px) {
      .ui-accordion-header a {
        font-size: 18px; } }

.ui-accordion .views-row {
  margin-bottom: 10px; }
  .ui-accordion .views-row:last-child {
    margin-bottom: 0; }
  .ui-accordion .views-row + .resources-category-description {
    margin-top: 20px; }

.ui-accordion .views-field-body a {
  color: #f63440; }
  .ui-accordion .views-field-body a:hover {
    text-decoration: underline; }

.ui-accordion .ui-accordion-icons {
  padding: 13px 50px 13px 23px;
  margin-top: 20px; }
  @media screen and (max-width: 400px) {
    .ui-accordion .ui-accordion-icons {
      padding: 13px 50px 13px 15px; } }

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  display: none; }

.ui-accordion .ui-accordion-content {
  border: none;
  border-radius: 0;
  padding: 25px 25px 10px 25px; }
  @media screen and (max-width: 400px) {
    .ui-accordion .ui-accordion-content {
      padding: 20px 15px 0; } }
  .ui-accordion .ui-accordion-content .title {
    font-weight: bold;
    padding-bottom: 12px; }
  .ui-accordion .ui-accordion-content .detail {
    margin-bottom: 21px; }
    .ui-accordion .ui-accordion-content .detail:last-child {
      margin-bottom: 0; }
  .ui-accordion .ui-accordion-content ul {
    margin: 0 0 15px 10px; }
    .ui-accordion .ui-accordion-content ul li {
      margin-bottom: 13px; }
      .ui-accordion .ui-accordion-content ul li:last-child {
        margin-bottom: 0; }

.field-name-field-architectural-solution > .field-items > .field-item {
  margin-bottom: 25px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 25px; }
  .field-name-field-architectural-solution > .field-items > .field-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none; }

.view-resources .view-content {
  margin-top: 44px;
  margin-bottom: 36px; }
  @media screen and (max-width: 767px) {
    .view-resources .view-content {
      margin-bottom: 25px; } }

/* Content top */
#content-top {
  background-color: #f0f0f1;
  color: #414042;
  padding: 50px; }
  @media screen and (max-width: 1024px) {
    #content-top {
      padding: 39px 0; } }

#content-top .region, #content-top .block {
  margin: 0; }

#content-top .block {
  padding: 20px 15px; }
  @media screen and (max-width: 767px) {
    #content-top .block {
      padding: 0; } }

#bottom {
  padding: 20px 0px 40px 0px;
  background: #3b3b3b;
  border-top: 5px solid #f63440; }

.footer-block {
  color: #aaa; }

.footer-block .block > h2 {
  font-size: 34px;
  margin-bottom: 10px;
  padding: 10px 0px;
  color: #fff;
  text-transform: uppercase; }
  @media screen and (max-width: 400px) {
    .footer-block .block > h2 {
      font-size: 28px; } }

/* Footer */
#footer-block {
  background-color: #e2e3e4;
  text-align: center;
  color: #414042; }

/* Footer Menu */
#footer-menu-block {
  background: url(../images/footer-menu-bg.png) no-repeat;
  margin: 5px 0 3px 0;
  padding: 30px 0 35px;
  background-size: cover; }
  @media screen and (max-width: 767px) {
    #footer-menu-block {
      background-size: 275% 100%;
      padding: 50px 0 0;
      margin: 0; }
      #footer-menu-block .container {
        padding: 0; } }

#footer-menu-block .footer-menu {
  padding: 20px 0 13px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    #footer-menu-block .footer-menu {
      padding: 0; } }
  @media screen and (max-width: 1023px) {
    #footer-menu-block .footer-menu img {
      max-width: 160px; } }

#footer-menu-block .footer-menu .left {
  float: left; }
  @media screen and (max-width: 767px) {
    #footer-menu-block .footer-menu .left {
      float: none;
      padding: 0 30px;
      margin-bottom: 45px; } }
  @media screen and (max-width: 480px) {
    #footer-menu-block .footer-menu .left {
      padding: 0 20px; } }

#footer-menu-block .footer-menu .right {
  float: right;
  padding-top: 15px;
  font-size: 14px; }
  @media screen and (max-width: 767px) {
    #footer-menu-block .footer-menu .right {
      padding: 27px 30px 17px;
      background: #c61d23;
      width: 100%; }
      #footer-menu-block .footer-menu .right ul {
        margin: 0; }
        #footer-menu-block .footer-menu .right ul li {
          font-size: 12px; }
          #footer-menu-block .footer-menu .right ul li:nth-child(2) {
            padding-left: 0; }
          #footer-menu-block .footer-menu .right ul li.first {
            display: none; } }
  @media screen and (min-width: 1024px) {
    #footer-menu-block .footer-menu .right {
      padding-top: 27px; } }
  @media screen and (max-width: 480px) {
    #footer-menu-block .footer-menu .right {
      padding-left: 20px;
      padding-right: 20px; } }

#footer-menu-block .footer-menu .right ul li {
  display: inline-block;
  float: left;
  color: #fff;
  text-transform: uppercase;
  padding: 0 0 0 15px;
  background: none; }
  @media screen and (max-width: 800px) {
    #footer-menu-block .footer-menu .right ul li {
      padding: 0 0 0 10px; } }
  @media screen and (max-width: 767px) {
    #footer-menu-block .footer-menu .right ul li {
      padding: 0 15px 10px 0; }
      #footer-menu-block .footer-menu .right ul li:last-child {
        padding-right: 0; } }
  @media screen and (min-width: 1024px) {
    #footer-menu-block .footer-menu .right ul li {
      padding: 0 0 0 38px; } }

#footer-menu-block .footer-menu .right ul li a {
  color: #fff;
  font-family: "proximanova-bold"; }

@media screen and (max-width: 767px) {
  .region-footer-copy {
    padding: 12px 50px; } }

@media screen and (max-width: 400px) {
  .region-footer-copy {
    padding: 12px 0; } }

#main-menu {
  padding: 0px 0px;
  margin: 25px 0px 0px 15px;
  height: 45px;
  border-bottom: none;
  float: right;
  font-family: "proximanova"; }
  @media screen and (max-width: 1024px) {
    #main-menu {
      float: none;
      margin: 0;
      padding: 0; } }

#main-menu ul {
  width: auto;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0 0px; }
  @media screen and (max-width: 1024px) {
    #main-menu ul {
      width: 100%;
      padding: 0 30px; } }
  @media screen and (max-width: 480px) {
    #main-menu ul {
      padding-left: 20px;
      padding-right: 20px; } }

#main-menu li {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  background: none; }
  @media screen and (max-width: 1024px) {
    #main-menu li {
      float: none;
      width: 100%;
      border-bottom: 1px solid #bfbfbf; } }

#main-menu li > a,
#main-menu li.follow-us {
  color: #fff;
  display: block;
  margin: 0;
  padding: 10px 0px 13px 0px;
  text-decoration: none;
  position: relative;
  font-weight: bold; }
  @media screen and (min-width: 1025px) {
    #main-menu li > a .follow-us-item,
    #main-menu li.follow-us .follow-us-item {
      display: none; } }
  @media screen and (max-width: 1024px) {
    #main-menu li > a,
    #main-menu li.follow-us {
      color: #000;
      font-size: 21px;
      text-transform: uppercase;
      width: 100%;
      padding: 20px 15px;
      font-weight: 400; } }

#main-menu li > a:hover, #main-menu li > a:active, #main-menu a.active, #main-menu > ul > li > a.active-trail {
  color: #f63440; }

#main-menu li a.sf-with-ul {
  padding-right: 10px; }

#main-menu li ul li a, #main-menu li ul li a:link, #main-menu li ul li a:visited,
#main-menu li ul li ul li a, #main-menu li ul li ul li a:link, #main-menu li ul li ul li a:visited,
#main-menu li ul li ul li ul li a, #main-menu li ul li ul li ul li a:link, #main-menu li ul li ul li ul li a:visited {
  color: #fff;
  width: 148px;
  margin: 0;
  padding: 10px 10px;
  border-top: 1px solid #ddd;
  position: relative;
  font-weight: 400; }

#main-menu ul li ul li:first-child a, #main-menu ul li ul li ul li:first-child a, #main-menu ul li ul li ul li ul li:first-child a {
  border-top: none; }

#main-menu li ul li a:hover, #main-menu li ul li ul li a:hover, #main-menu li ul li ul li ul li a:hover {
  color: #f63440; }

#main-menu li ul {
  font-size: 12px;
  z-index: 9999;
  position: absolute;
  left: -999em;
  height: auto;
  width: 160px;
  margin: 0px 0px 0px 0px;
  padding: 5px 5px;
  background: #efefef; }

#main-menu li ul a {
  width: 150px; }

#main-menu li ul ul {
  margin: -44px 0 0 160px; }

#main-menu li:hover ul ul, #main-menu li:hover ul ul ul, #main-menu li.sfHover ul ul, #main-menu li.sfHover ul ul ul {
  left: -999em; }

#main-menu li:hover ul, #main-menu li li:hover ul, #main-menu li li li:hover ul, #main-menu li.sfHover ul, #main-menu li li.sfHover ul, #main-menu li li li.sfHover ul {
  left: auto; }

#main-menu li:hover, #main-menu li.sfHover {
  position: static; }

#main-menu > ul > li > a {
  padding: 10px 25px 13px 25px; }
  @media screen and (max-width: 1024px) {
    #main-menu > ul > li > a {
      padding: 16px 15px; } }
  @media screen and (min-width: 1025px) and (max-width: 1190px) {
    #main-menu > ul > li > a {
      padding: 10px 17px 13px 17px; } }

/* Menu items */
#main-menu li a.login {
  color: #f63440; }

span.login-icon {
  background: url("../images/KKG-Login.svg") no-repeat;
  padding-left: 15px;
  margin-left: 10px;
  background-size: 42px auto;
  background-position: -13px center; }
  @media screen and (max-width: 1024px) {
    span.login-icon {
      padding-left: 30px;
      background-size: 60px auto; } }

#main-menu li a.facebook-link {
  padding-right: 2px;
  padding-left: 5px; }
  @media screen and (max-width: 1024px) {
    #main-menu li a.facebook-link {
      padding-left: 17px; } }

#main-menu li a.facebook-link span.facebook-icon {
  background: url(../images/KKG-Facebook.svg) no-repeat;
  padding-left: 20px;
  background-size: 50px;
  background-position: -15px center; }
  @media screen and (max-width: 1024px) {
    #main-menu li a.facebook-link span.facebook-icon {
      background-image: url("../images/KKG-Facebook-red.svg");
      background-size: 64px;
      padding-left: 33px; } }

#main-menu li a.linkedin-link {
  padding-left: 2px;
  padding-right: 0; }

#main-menu li a.linkedin-link span.linkedin-icon {
  background: url("../images/KKG-Linkedin.svg") no-repeat;
  padding-left: 20px;
  background-size: 50px;
  background-position: -15px center; }
  @media screen and (max-width: 1024px) {
    #main-menu li a.linkedin-link span.linkedin-icon {
      background-image: url("../images/KKG-Linkedin-red.svg");
      background-size: 64px;
      padding-left: 33px; } }

/* About us */
#block-views-our-capabilities-block {
  padding: 23px 0 37px; }

#block-views-our-capabilities-block > h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 40px; }

#block-views-our-partners-block > h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  padding: 20px 0 0 0; }

.page-node #content-wrap .view-our-partners {
  text-align: center;
  display: inline-block;
  padding-bottom: 18px; }
  @media screen and (min-width: 768px) {
    .page-node #content-wrap .view-our-partners {
      width: 85%;
      margin: 0 auto 18px; } }

.view-our-partners .view-content .views-row {
  width: 33.3%;
  float: left; }
  @media screen and (min-width: 768px) {
    .view-our-partners .view-content .views-row {
      height: 142px; } }

.page-node #content-wrap .view-our-partners .view-content .views-row .views-field-field-image img {
  border: none;
  margin: 0;
  padding: 0;
  height: auto; }

.view-our-partners ul.views-fluid-grid-list, .view-our-partners ul.views-fluid-grid-list li {
  background: transparent;
  border: none; }

.page-node-56,
.page-node-106 {
  text-align: center; }
  .page-node-56 .field-name-body,
  .page-node-106 .field-name-body {
    color: #414042; }
  .page-node-56 .region-content,
  .page-node-106 .region-content {
    margin-bottom: 30px; }
  .page-node-56 #content-bottom,
  .page-node-106 #content-bottom {
    padding: 30px 0 40px;
    background: #f9f9f9; }
  .page-node-56 #content-wrap,
  .page-node-106 #content-wrap {
    margin: 0 auto 0; }
  .page-node-56 .messages,
  .page-node-56 #footer-block,
  .page-node-106 .messages,
  .page-node-106 #footer-block {
    display: none; }

.social-links {
  display: flex;
  margin: 0 -5px; }
  .social-links > span {
    position: relative;
    line-height: 0;
    max-width: 300px;
    padding: 0 5px; }
  .social-links a {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .social-links .facebook-icon,
  .social-links .linkedin-icon {
    position: absolute;
    left: -10px;
    top: -15px;
    width: 70px;
    background-size: cover;
    height: 70px;
    background: url("../images/KKG-Facebook.svg") no-repeat scroll 0 0 transparent;
    background-size: cover; }
  .social-links .linkedin-icon {
    background-image: url("../images/KKG-Linkedin.svg"); }

.page-node-56 .quick-contact {
  display: none !important; }
