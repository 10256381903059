/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
  overflow: hidden;
  position: absolute;
  text-align: left;
  text-indent: -999em;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  border: 0;
  width: 80px;
  height: 80px;
  padding: 40px;
  background: none;

  &:before {
    @extend .icons;
    @extend .icon-arrow_right:before ;
    font-size: 80px;
    line-height: 1;
    position: absolute;
    text-indent: 0;
    top: 0;
    color: #fff;
    opacity: .75;
  }

  &:hover {
    background: none;
  }

  &:focus {
    outline: none;
  }
}

.slick-prev {
  left: -15px;

  &:before {
    left: 0;
    transform: rotate(-180deg);
  }
}

.slick-next {
  right: -15px;

  &:before {
    right: 0;
  }
}
