.slideshow-block {
  overflow: hidden;
  margin-top: -1px;

  .views-field-field-image {
    width: 100%;
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(#000, .15);
    }

    @media screen and (max-width: 767px) {
      height: 433px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        transform: translate(-50%, -50%);
        max-width: inherit;
      }
    }

    @media screen and (min-width: 1280px) {
      height: calc(100vh + 8px);
    }

    .field-content {
      width: 100%;
      height: 100%;
    }
  }
}
.slideshow-block .views-field-title {
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  font-size: 30px;
  font-weight: bolder;
  width: 100%;
  text-align: center;
  padding: 0 70px;
  transform: translateY(-50%);
  font-family: "proximanova-bold";
  line-height: 1.1;

  @media screen and (max-width: 767px) {
    padding: 0 40px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 20%;
    font-size: 40px;
  }

  .field-content {
    background: url("../images/bg-text-slider.png") no-repeat scroll center center transparent;
    background-size: 100% 100%;
    display: inline-block;

    @media screen and (min-width: 1024px) {
      padding: 50px 20px;
    }
  }

  a {

  }
}

.slideshow-block .views-field-title a,
.slideshow-block .views-field-title {
  color: #fff;
}

#block-views-our-partners-block, #block-views-messages-block {
  width: 100%;

  @media screen and (min-width: 768px) {
    text-align: center;
  }
}

.message-block {
  @media screen and (max-width: 767px) {
    padding: 21px 0;
  }
}

.box-service {
  overflow: hidden;
  padding: 6px 0;
  margin-bottom: 15px;

  .view-content {
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .views-row {
    position: relative;
    height: auto;
    display: flex;

    @media screen and (max-width: 767px) {
      margin-bottom: 38px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 400px) {
      flex-wrap: wrap;
      text-align: center;
    }

    @media screen and (min-width: 768px) {
      width: 286px;
      height: 288px;
      justify-content: center;
      align-items: flex-end;
      height: 250px;
      padding-bottom: 30px;
    }

    @media screen and (min-width: 1024px) {
      padding-bottom: 0;
      align-items: center;
    }

    &:hover {
      .box-service__content {
        display: block;
      }

      .box-service__wrap h3 {
        opacity: 0;
      }
    }
  }

  &__image {
    margin-bottom: 29px;


    @media screen and (max-width: 1023px) {
      img {
        max-width: 100px;
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 767px) {
      margin: 0 48px 0 0;

      img {
        max-width: 123px;
      }
    }

    @media screen and (max-width: 400px) {
      margin: 0 auto;
    }
  }

  &__wrap {
    @media screen and (max-width: 400px) {
      margin: 0 auto 10px;
    }
    > .box-service__title {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__content {
    @media screen and (min-width: 768px) {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(#e6e7e8, .97);
      padding: 20px 10px;
      overflow: auto;
    }

    @media screen and (min-width: 1024px) {
      padding: 20px;
    }

    @media screen and (max-width: 1023px) and (min-width: 768px) {
      font-size: 14px;
    }

    .box-service__title {
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        margin-bottom: 11px;
      }
    }

    p {
      margin: 0;
    }
  }
}

.all-products-block {
  margin-bottom: 30px;
}

.warranty {
  .view-content {
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .views-row {
    position: relative;

    @media screen and (max-width: 767px) {
      height: 251px;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media screen and (min-width: 768px) {
      width: 33.33%;
    }

    > .warranty__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      margin: 0;
      background: url("../images/bg-text.png") no-repeat scroll center center transparent;
      background-size: 100% 100%;
      padding: 20px;
      transition: .3s;

      @media screen and (min-width: 768px) {
        padding: 20px 0;
      }
    }

    &:hover {
      .warranty__content {
        background: rgba(#f63440, .6);
        transition: .3s;
      }

      .warranty__inner {
        transition: .3s;
        opacity: 1;
        visibility: visible;
      }

      > .warranty__title {
        opacity: 0;
        transition: .3s;
      }
    }
  }

  &__title {
    color: #fff;
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 1;
    padding: 20px;

    @media screen and (min-width: 768px) {
      max-width: 310px;
      padding: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      font-size: 24px;
    }

    @media screen and (max-width: 400px) {
      font-size: 24px;
    }
  }

  &__image {
    line-height: 0;

    img {
      width: 100%;
      height: auto;
    }

    a {
      &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        content: '';
      }
    }
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    padding: 20px 10px;
    background: rgba(#000, .1);
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;

    @media screen and (min-width: 1024px) {
      padding: 30px 15px;
    }

    h3 {
      display: none;
      max-width: inherit;

      @media screen and (min-width: 1024px) {
        display: block;
      }

      @media screen and (max-width: 1024px) {
        margin-bottom: 5px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 35px;
      }
    }
  }

  &__inner {
    opacity: 0;
    transition: .3s;
    visibility: hidden;
  }

  &__body {
    margin-bottom: 40px;
    padding: 0 20px;

    @media screen and (max-width: 1024px) {
      font-size: 14px;
      margin-bottom: 30px;
      padding: 0;
    }
  }

  &__link {
    a {
      border: 1px solid #fff;
      color: #fff;
      text-transform: uppercase;
      padding: 11px 35px;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
        padding: 9px 35px;
      }

      &:before {
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        transition: .3s;
        position: absolute;
        width: 100%;
        content: '';
      }
    }
  }
}

.recent-projects-block {
  text-align: center;
  padding: 90px 0 61px;

  @media screen and (max-width: 767px) {
    padding: 40px 0 30px;
  }

  h2 {
    margin-bottom: 65px;

    @media screen and (max-width: 767px) {
      margin-bottom: 19px;
    }
  }
}


.grid-3cols,
.grid-4cols {
  .view-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .views-row {
    margin-bottom: 25px;
    margin-right: 25px;

    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
      width: 100%;
    }

    a{
      position: relative;
      height: 100%;
      display: inline-block;
      width: 100%;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(#f63440, .6);
        opacity: 0;
        visibility: hidden;
        transition: .3s;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &:hover {
      a:before {
        opacity: 1;
        visibility: visible;
        transition: .3s;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .views-field-colorbox {
    line-height: 0;
  }
}

.grid-4cols {
  .views-row {
    width: calc((100% - 32px) / 4);
    margin-bottom: 0;
    margin-right: 10px;

    @media screen and (max-width: 767px) {
      width: calc((100% - 8px) / 2);
      margin-bottom: 5px;
      margin-right: 7px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.grid-3cols {
  .views-row {
    @media screen and (min-width: 768px) {
      width: calc((100% - 52px) / 3);
    }

    @media screen and (max-width: 767px) {
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.block-simplenews {
  padding: 34px 0 53px;
  margin: 0 auto;
  max-width: 950px;

  @media screen and (max-width: 767px) {
    padding: 12px 0 42px;
  }

  p {
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      margin-bottom: 36px;
    }
  }

  .form-text {
    margin: 0;
    height: 38px;
    padding: 10px 15px;
    width: 100%;
    background: #fff;

    @media screen and (min-width: 768px) {
      margin: 0 10px;
    }
  }

  .webform-component-textfield,
  .webform-component-email {
    @media screen and (min-width: 768px) {
      width: 50%;
      float: left;
      padding: 0 15px;
    }
  }

  .form-submit {
    margin-top: 24px !important;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      margin-top: 17px !important;
    }
  }
}

.next-section {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  color: #c3beba;
  display: none;

  @media screen and (min-width: 1280px) {
    display: block;
  }

  &:hover {
    color: #f63440;
  }

  span {
    cursor: pointer;
    font-size: 130px;
  }
}

.quick-contact {
  background: rgba(#001489, .5);
  position: fixed;
  top: 60%;
  width: 768px;
  padding: 18px 14px 14px 55px;
  z-index: 9;
  right: -727px;
  transition: .3s;
  display: none;
  height: 206px;

  label,
  .webform-component-markup,
  .webform-component--what-is-the-first-letter-in-glass {
    display: none !important;
  }

  @media screen and (min-width: 768px) {
    display: block;
  }

  &.is-show {
    right: 0;
    transition: .3s;
  }

  .webform-client-form {
    padding: 0;
    min-width: inherit;
  }

  .messages {
    display: none;
  }

  .form-item-captcha-questions-answer-given {
    display: none;
  }

  &__title {
    font-size: 21px;
    text-transform: uppercase;
    position: absolute;
    left: -83px;
    top: 83px;
    transform: rotate(-90deg);
    background: url('../images/bg-quick-contact.png') no-repeat scroll 0 0 transparent;
    background-size: cover;
    color: #fff;
    padding: 8px 5px 5px;
    margin: 0;
    letter-spacing: 0px;
    cursor: pointer;
    width: 206px;
    text-align: center;
  }

  form {
    > div {
      display: flex;
    }

    .form-text {
      max-width: 239px;
      margin-top: 0;
      height: 33px;
      background: #fff;
    }
  }

  .form-item {
    margin-top: 0;
    margin-bottom: 7px;
  }

  .form-actions {
    margin: 12px 0 0;
    padding: 0;
    max-width: 239px;

    input {
      margin: 0;
      width: 100%;
      color: #fff;
      border: 1px solid #fff;
      text-transform: uppercase;

      &:hover {
        border-color: #c61d23;
      }
    }
  }

  .grippie {
    display: none;
  }

  .form-item-subject {
    display: none;
  }

  .form-type-textarea,
  .webform-component-textarea {
    margin-left: 10px;
    margin-bottom: 0;

    textarea {
      height: 170px;
      padding: 10px;
      background: #fff;
    }
  }

  .form-item-copy {
    display: none;
  }
}


#block-views-about-us-block .view-content .views-field-body {
  padding-bottom: 10px;

  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
}

.front {
  #primary {
    @media screen and (max-width: 1000px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.read-more {
  position: relative;

  &:before {
    position: absolute;
    right: -30px;
    background: url(../images/icons/list-style.png) no-repeat;
    padding-left: 15px;
    top: 3px;
    content: '';
    width: 10px;
    height: 15px;
  }
}

textarea,
input,
button {
  border-radius: 0;
}
