.page-node-56,
.page-node-106 {
  text-align: center;

  .field-name-body {
    color: #414042;
  }

  .region-content {
    margin-bottom: 30px;
  }

  #content-bottom {
    padding: 30px 0 40px;
    background: #f9f9f9;
  }

  #content-wrap {
    margin: 0 auto 0;
  }

  .messages,
  #footer-block {
    display: none;
  }
}

.social-links {
  display: flex;
  margin: 0 -5px;

  > span {
    position: relative;
    line-height: 0;
    max-width: 300px;
    padding: 0 5px;
  }

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .facebook-icon,
  .linkedin-icon {
    position: absolute;
    left: -10px;
    top: -15px;
    width: 70px;
    background-size: cover;
    height: 70px;
    background: url('../images/KKG-Facebook.svg') no-repeat scroll 0 0 transparent;
    background-size: cover;
  }

  .linkedin-icon {
    background-image: url('../images/KKG-Linkedin.svg')
  }
}

.page-node-56 {
  .quick-contact {
    display: none !important;
  }
}
