.node-type-products {
  .field-name-field-image {
    margin-bottom: 39px;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 400px) {
      margin-left: -20px;
      margin-right: -20px;
    }

    .field-items {
      display: flex;
      justify-content: center;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .field-name-body {
    margin-bottom: 35px;

    @media screen and (max-width: 767px) {
      margin-bottom: 48px;
    }
  }

  .product-specification-title {
    text-align: center;
    margin-bottom: 29px;
    line-height: 1.2;
  }

  .group-product-specifications {
    @media screen and (max-width: 767px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .region-content {
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .field-collection-container,
  .field-collection-container .field-items .field-item {
    margin-bottom: 0;
    border: 0;
  }

  .field-name-field-architectural-solution {
    .field-collection-view {
      margin: 0 0 30px;
      padding: 0 0 30px;
      border-bottom: 1px solid #ccc;
    }

    .field-collection-view-final {
      margin: 0;
    }

    .content {
      display: flex;
    }

    .field-type-image {
      width: 29%;
      line-height: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    .field-name-field-solution-information {
      width: 71%;
      padding-left: 15px;
    }
  }
}

.resource-region {
  background: url('../images/bg-resource.jpg') no-repeat scroll 0 0 transparent;
  background-size: cover;
  color: #fff;
  padding: 36px 0 31px;
}

.region-resource {
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    font-size: 21px;
    color: #fff;
  }

  a {
    color: #fff;
  }

  .product-resources-block {
    @media screen and (min-width: 768px) {
      width: 61%;
    }
    position: relative;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
      overflow: hidden;
    }

    @media screen and (max-width: 1370px) {
      padding-left: 105px;
    }

    &:before {
      position: absolute;
      content: '';
      left: -105px;
      top: -5px;
      background: url(../images/resources.svg) no-repeat scroll 0 0 transparent;
      width: 78px;
      height: 87px;

      @media screen and (max-width: 1370px) {
        left: 0;
        top: 0;
      }
    }

    .item-list {

      ul {
        padding-left: 0;
      }

      li {
        @media screen and (min-width: 1025px) {
          width: calc((100% - 11px) / 2);
          float: left;
          padding-left: 22px;
          margin-right: 10px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        margin-bottom: 10px;
        background: none;



        a {
          position: relative;

          &:before {
            @extend .icons;
            @extend .icon-arrow_right:before;
            position: absolute;
            left: -30px;
            top: -3px;
            font-size: 27px;
            width: 20px;
          }
        }
      }
    }
  }

  #block-views-warranty-block {
    text-align: center;

    @media screen and (max-width: 767px) {
      background: url('../images/bg-download.jpg') no-repeat scroll 0 0 transparent;
      background-size: 100% 100%;
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: -30px;
      padding: 30px 0;

      @media screen and (max-width: 400px) {
        margin-left: -20px;
        margin-right: -20px;
      }
    }

    @media screen and (min-width: 768px) {
      width: 35%;
    }

    @media screen and (min-width: 1280px) {
      padding-right: 160px;
    }
  }

  .views-field-field-warranty a {
    padding: 10px 45px;
    font-size: 16px;
    border: 1px solid #fff;
    color: #fff;
    margin-top: 9px;
    display: inline-block;
    text-transform: uppercase;
  }
}

.region-related-product {
  text-align: center;
  padding: 49px 0;

  h2 {
    margin-bottom: 36px;
  }
}

.view-related-products,
.menu-product  {
  .view-content {
    margin: 0 -12px;
  }

  .views-row {
    padding: 0 12px;
    height: auto;

    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }

    &:hover {
      a:before {
        background: rgba(#f63440, .6);
        transition: .3s;
      }

      > .warranty__title {
        background: none;
        transition: .3s;
        opacity: 1;
      }
    }

    a {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, .35);
        content: '';
        transition: .3s;
        z-index: 2;
      }
    }

    img {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }
  }

  .warranty__title {
    padding: 0 20px !important;
    z-index: 2;
    transition: .3s;

    @media screen and (min-width: 768px) {
      font-size: 24px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 28px;
    }
  }
}
#related-product-block {
  overflow: hidden;
}
.view-related-products {
  .slick-prev {
    left: -35px;

    @media screen and (min-width: 480px) {
      left: -40px;
    }

    @media screen and (min-width: 768px) {
     left: -50px;
    }
  }

  .slick-next {
    right: -35px;

    @media screen and (min-width: 480px) {
      right: -43px;
    }

    @media screen and (min-width: 768px) {
      right: -53px;
    }
  }

  .slick-arrow:before {
    color: #f63440;
    opacity: 1;
  }

  .warranty__title {
    @media screen and (max-width: 767px) {
      font-size: 21px;
    }
  }
}

.all-products-block,
.view-taxonomy-term {
  .view-header {
    margin-bottom: 20px;
  }
  .views-row {
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
      margin-right: 25px;
      width: calc((100% - 52px) / 3);
      margin-bottom: 20px;
      height: auto;
      overflow: hidden;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    img {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
      max-width: inherit;
      width: 100%;
      height: auto;
    }
  }

  .views-field-field-image {
    line-height: 0;
  }
}
