.ui-accordion-header {
  border: none;
  border-radius: 0;
  background: #f0f1f1;
  padding: 13px 10px 12px 20px;
  position: relative;


  &:before {
    content: '';
    background: url("../images/icons/accordion-close.png") no-repeat;
    position: absolute;
    top: 50%;
    right: 17px;
    width: 28px;
    height: 12px;
    margin-top: -6px;

    @media screen and (max-width: 400px) {
      width: 20px;
      height: 10px;
      margin-top: -4px;
      background-size: 20px;
    }
  }

  &.ui-accordion-header-active:before {
    background-image: url("../images/icons/accordion-active.png");
  }

  a {
    font-weight: bolder;
    font-size: 21px;
    text-transform: uppercase;
    color: #414042 !important;

    @media screen and (max-width: 400px) {
      font-size: 18px;
    }
  }
}

.ui-accordion {
  .views-row {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    + .resources-category-description {
      margin-top: 20px;
    }
  }

  .views-field-body {
    a {
      color: #f63440;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .ui-accordion-icons {
    padding: 13px 50px 13px 23px;
    margin-top: 20px;

    @media screen and (max-width: 400px) {
      padding: 13px 50px 13px 15px;
    }
  }

  .ui-accordion-header .ui-accordion-header-icon {
    display: none;
  }

  .ui-accordion-content {
    border: none;
    border-radius: 0;
    padding: 25px 25px 10px 25px;

    @media screen and (max-width: 400px) {
      padding: 20px 15px 0;
    }

    .title {
      font-weight: bold;
      padding-bottom: 12px;
    }

    .detail {
      margin-bottom: 21px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin: 0 0 15px 10px;

      li {
        margin-bottom: 13px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.field-name-field-architectural-solution > .field-items > .field-item {
  margin-bottom: 25px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
}
.view-resources {
  .view-content {
    margin-top: 44px;
    margin-bottom: 36px;

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }
}
