.projects-block {
  margin-top: 44px;
  margin-bottom: 27px;

  @media screen and (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .views-row {

  }
}

#cboxWrapper {
  background: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto!important;

  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
}

#cboxPrevious {
  background: url('../images/icons/prev.png') no-repeat scroll center center transparent;
  left: -40px;
  @media screen and (min-width: 1025px) {
    left: -79px;
  }
}
#cboxPrevious,
#cboxNext {
  top: 50%;
  height: 40px;
  width: 50px;
  margin-bottom: 0;
  outline: none;
  bottom: inherit;
  transform: translate(0, -50%);

  &:focus {
    outline: none;
  }

  &:hover {
    background-position: center center;
  }
}

#cboxNext  {
  left: inherit;
  background: url('../images/icons/next.png') no-repeat scroll center center transparent;
  right: -40px;
  @media screen and (min-width: 1025px) {
    right: -79px;
  }
}

#cboxClose  {
  background: url('../images/icons/close.svg') no-repeat scroll 0 0 transparent;
  background-size: 17px auto;
  position: fixed;
  top: 15px;
  right: 10px;
  display: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background-position: 0 0;
  }
}

#cboxCurrent {
  display: none !important;
}

#cboxLoadedContent {
  margin: 0;
  height: auto !important;

  @media screen and (max-width: 1024px) {
    height: auto !important;
    width: 100% !important;
  }

  .field-title {
    display: none;
  }
}

#colorbox,
#cboxOverlay,
#cboxWrapper {
  overflow: inherit;
}

#cboxOverlay {
  background: none;
  opacity: 1 !important;
  transition: .3s;

  &:before {
    background: url('../images/icons/close.svg') no-repeat scroll 0 0 transparent;
    background-size: 17px auto;
    position: absolute;
    top: 15px;
    right: 10px;
    content: '';
    width: 17px;
    height: 17px;
    z-index: 2;
    transition: .3s;
  }

  &:after {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .75 !important;
    background: #000;
    z-index: 1;
    transition: .3s;
  }
}

#cboxMiddleLeft,
#cboxMiddleRight {
  width: 1px;
  height: 100px !important;
}

#colorbox {
  @media screen and (max-width: 1024px) {
    width: 80% !important;
    left: 50% !important;
    top: 50% !important;
    position: fixed !important;
    transform: translate(-50%, -50%);
    margin-top: -7px;
  }
}


#cboxContent {
  position: relative;
  overflow: inherit;
  background: none;
  height: auto !important;

  @media screen and (max-width: 1024px) {
   width: 100% !important;
 }

  .field-image {
    line-height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: auto;
      max-height: 95vh;
    }
  }
}

.field-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000, .75);
  color: #fff;
  padding: 10px 20px;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 13px;
    padding: 5px;
  }
}
